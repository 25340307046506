<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04">
          <img
            src="@/assets/front_component/images/assets/back02.png"
            alt="雲"
          />
        </div>
      </div>
      <div class="left_area">
        <div class="img01">
          <img
            src="@/assets/front_component/images/assets/back01.png"
            alt="雲"
          />
        </div>
      </div>
    </div>
    <div class="en_ttl en"><span>MY EVENT</span></div>
    <div class="content_wrap">
      <h2>My イベント</h2>
      <div class="mascot_wrap" style="display: none">
        <img
          src="@/assets/front_component/images/assets/meguru05.png"
          alt="めぐる君"
        />
      </div>
      <div
        class="user_img"
        :style="{ backgroundImage: 'url(' + profileImg + ')' }"
        v-if="profileImg"
      ></div>
      <div
        class="user_img"
        :style="{
          backgroundImage:
            'url(' +
            require('@/assets/front_component/images/test/test01.png') +
            ')',
        }"
        v-else
      ></div>
    </div>
  </div>
  <main>
    <div class="section_all_wrap">
      <div class="content_in_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li>
              <router-link
                :to="{
                  name: 'Control event',
                  query: {
                    row_count: this.$route.query.row_count
                      ? this.$route.query.row_count
                      : 25,
                  },
                }"
                >My イベント一覧</router-link
              >
            </li>
            <li>
              <span>{{ showevent.title }}</span>
            </li>
          </ul>
        </div>
        <div class="content">
          <div class="innar">
            <div class="post_header">
              <div class="post_header_icon">
                <img
                  src="@/assets/front_component/images/assets/tab01_out.png"
                  alt="すべて表示"
                />
              </div>
              <div class="post_header_day">
                <span class="post_header_day_time"
                  ><img
                    src="@/assets/front_component/images/assets/board01.png"
                    alt="時間"
                  /><time
                    class="en"
                    :datetime="formatDate_time(showevent.created_at)"
                    >{{ formatDate(showevent.created_at) }}</time
                  ></span
                >
                <span v-if="showevent.from_user" class="post_header_day_name"
                  >{{ showevent.from_user.group.name }}：{{
                    showevent.from_user.last_name
                  }}
                  {{ showevent.from_user.first_name }}</span
                >
              </div>
            </div>
            <article class="post_area">
              <h1>{{ showevent.title }}</h1>
              <h2 v-if="showevent.reserved > 0">
                参加申込者一覧<span
                  >({{ showevent.reserved }}名/<span
                    v-if="showevent.capacity != null"
                    >{{ showevent.capacity }}</span
                  ><span v-else>設定なし</span>)</span
                >
              </h2>
              <div v-if="entry && showevent.reserved > 0" class="js-scrollable">
                <table class="tbl_list">
                  <tr class="sort">
                    <th class="tbl_list_sta t_c">
                      <div class="sort_ttl"><span>参加者</span></div>
                    </th>
                    <th class="tbl_list_ttl">
                      <div class="sort_ttl"><span>メールアドレス</span></div>
                    </th>
                    <th class="tbl_list_sta t_c">
                      <div class="sort_ttl"><span>予約日</span></div>
                    </th>
                    <th class="tbl_list_sta t_c">
                      <div class="select_list_wrap">
                        <ul class="select_list">
                          <li class="select_label-placeholder">
                            <span>ステータス</span>
                          </li>
                          <li class="select_items">
                            <ul class="select_options">
                              <li class="select_option">
                                <input
                                  v-model="status"
                                  class="select_input"
                                  type="radio"
                                  name="statuschange"
                                  id="status_koukai"
                                  v-on:change="onStatusChange"
                                  value="all"
                                />
                                <label class="select_label" for="status_koukai"
                                  >全て</label
                                >
                              </li>

                              <li class="select_option">
                                <input
                                  v-model="status"
                                  class="select_input"
                                  type="radio"
                                  name="statuschange"
                                  id="status_shitagaki"
                                  v-on:change="onStatusChange"
                                  value="受付済み"
                                />
                                <label
                                  class="select_label"
                                  for="status_shitagaki"
                                  >受付済み</label
                                >
                              </li>

                              <li class="select_option">
                                <input
                                  v-model="status"
                                  class="select_input"
                                  type="radio"
                                  name="statuschange"
                                  id="status_archive"
                                  v-on:change="onStatusChange"
                                  value="キャンセル待ち"
                                />
                                <label class="select_label" for="status_archive"
                                  >キャンセル待ち</label
                                >
                              </li>

                              <li class="select_option">
                                <input
                                  v-model="status"
                                  class="select_input"
                                  type="radio"
                                  name="statuschange"
                                  id="status_archive"
                                  v-on:change="onStatusChange"
                                  value="キャンセル"
                                />
                                <label class="select_label" for="status_archive"
                                  >キャンセル</label
                                >
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </th>
                    <th class="tbl_list_command">
                      <div class="sort_ttl"><span>操作</span></div>
                    </th>
                  </tr>
                  <tr v-for="user_list in entry.slice()" :key="user_list.id">
                    <td v-if="user_list.from_user" class="tbl_list_ttl">
                      {{ user_list.from_user.last_name
                      }}{{ user_list.from_user.first_name }}
                    </td>
                    <td v-if="user_list.from_user" class="tbl_list_ttl">
                      {{ user_list.from_user.email }}
                    </td>
                    <td class="tbl_list_ttl">
                      <img
                        src="@/assets/front_component/images/assets/board01.png"
                        alt="時間"
                      /><time
                        class="en"
                        :datetime="formatDate_time(user_list.created_at)"
                        >{{ formatDate(user_list.created_at) }}</time
                      >
                    </td>
                    <td class="tbl_list_sta t_c">{{ user_list.status }}</td>
                    <td class="tbl_list_command">
                      <div class="commandlist">
                        <div
                          v-if="user_list.status == '受付済み'"
                          class="command"
                        >
                          <a
                            class="cancel"
                            @click="
                              enterstatuschenge(user_list.id, 'キャンセル')
                            "
                            data-tip="キャンセル"
                            >キャンセル</a
                          >
                        </div>
                        <div
                          v-else-if="user_list.status == 'キャンセル待ち'"
                          class="command"
                        >
                          <a
                            class="delete"
                            @click="enterstatuschenge(user_list.id, '受付済み')"
                            data-tip="受付済み"
                            >受付済み</a
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
                <div class="t_c" v-if="next_page_id != null">
                  <div class="btn_wide">
                    <button v-on:click="EntryListmore">さらに読み込む</button>
                  </div>
                </div>
              </div>

              <div v-else-if="showevent.reserved > 0" class="js-scrollable">
                <table class="tbl_list">
                  <tr class="sort">
                    <th class="tbl_list_sta t_c">
                      <div class="sort_ttl"><span>参加者</span></div>
                    </th>
                    <th class="tbl_list_ttl">
                      <div class="sort_ttl"><span>メールアドレス</span></div>
                    </th>
                    <th class="tbl_list_sta t_c">
                      <div class="sort_ttl"><span>予約日</span></div>
                    </th>
                    <th class="tbl_list_sta t_c">
                      <div class="select_list_wrap">
                        <ul class="select_list">
                          <li class="select_label-placeholder">
                            <span>ステータス</span>
                          </li>
                          <li class="select_items">
                            <ul class="select_options">
                              <li class="select_option">
                                <input
                                  v-model="status"
                                  class="select_input"
                                  type="radio"
                                  name="statuschange"
                                  id="status_koukai"
                                  v-on:change="onStatusChange"
                                  value="公開"
                                />
                                <label class="select_label" for="status_koukai"
                                  >公開</label
                                >
                              </li>

                              <li class="select_option">
                                <input
                                  v-model="status"
                                  class="select_input"
                                  type="radio"
                                  name="statuschange"
                                  id="status_shitagaki"
                                  v-on:change="onStatusChange"
                                  value="下書き"
                                />
                                <label
                                  class="select_label"
                                  for="status_shitagaki"
                                  >下書き</label
                                >
                              </li>

                              <li class="select_option">
                                <input
                                  v-model="status"
                                  class="select_input"
                                  type="radio"
                                  name="statuschange"
                                  id="status_archive"
                                  v-on:change="onStatusChange"
                                  value="アーカイブ"
                                />
                                <label class="select_label" for="status_archive"
                                  >アーカイブ</label
                                >
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </th>
                    <th class="tbl_list_command">
                      <div class="sort_ttl"><span>操作</span></div>
                    </th>
                  </tr>
                </table>
              </div>

              <div class="eyecatch">
                <img id="img_tag_id" />
              </div>

              <table>
                <tbody>
                  <tr>
                    <th>開催場所</th>
                    <td>{{ showevent.place }}</td>
                  </tr>
                  <tr>
                    <th>開始日時</th>
                    <td>
                      <time
                        v-if="showevent.implementation_datetime"
                        class="en"
                        :datetime="
                          formattime(
                            showevent.implementation_datetime.start_datetime
                          )
                        "
                        >{{
                          formattime(
                            showevent.implementation_datetime.start_datetime
                          )
                        }}</time
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>終了日時</th>
                    <td v-if="showevent.implementation_datetime">
                      <time
                        v-if="showevent.implementation_datetime.end_datetime"
                        class="en"
                        :datetime="
                          formattime(
                            showevent.implementation_datetime.end_datetime
                          )
                        "
                        >{{
                          formattime(
                            showevent.implementation_datetime.end_datetime
                          )
                        }}</time
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>参加人数<br />(参加申請/最大)</th>
                    <td>
                      {{ showevent.reserved }}/<span
                        v-if="showevent.capacity != null"
                        >{{ showevent.capacity }}</span
                      ><span v-else>設定なし</span>
                    </td>
                  </tr>
                  <tr>
                    <th>問い合わせ先</th>
                    <td>{{ showevent.contact }}</td>
                  </tr>
                </tbody>
              </table>
              <h2>詳細</h2>
              <p v-html="showevent.content"></p>

              <div v-if="showevent.attached_file">
                <h3>関連資料</h3>
                <ul class="doc_list">
                  <li
                    class="doc_wrap"
                    v-for="file in showevent.attached_file"
                    :key="file.id"
                  >
                    <div class="doc_left">
                      <p>{{ file.display_name }}</p>
                    </div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a
                          v-on:click="dlfile(file.storage_file_name)"
                          class="en"
                          target="_blank"
                          :download="file.updaload_name"
                          >DL</a
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </article>

            <div class="t_c m_t100 m_b50">
              <div class="btn_wide blue">
                <router-link
                  :to="{
                    name: 'Control event',
                    query: {
                      row_count: this.$route.query.row_count
                        ? this.$route.query.row_count
                        : 25,
                    },
                  }"
                  >一覧にもどる</router-link
                >
              </div>
            </div>
          </div>
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/bard01.png"
              alt="青鳥"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/bard02.png"
              alt="緑鳥"
            />
          </div>
        </div>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/kusa02.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/kusa05.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap09">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap10">
            <img
              src="@/assets/front_component/images/assets/kusa07.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap11">
            <img
              src="@/assets/front_component/images/assets/kusa06.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap12">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap13">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap14">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap15">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap16">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap17">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kikyu01.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/heri01.png"
              alt="ヘリコプター"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kikyu02.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kikyu03.png"
              alt="気球"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img
        src="@/assets/front_component/images/assets/menu01.png"
        alt="メニュー"
      />
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
      <ul class="menu_list">
        <!-- <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'news' }}">お知らせ</router-link></li> -->
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'thanks' } }"
            >サンクス&ホメロンカード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'idea' } }"
            >創発カード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'daily' } }"
            >気づき日報</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'photolog' },
            }"
            >フォトログ</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'column' } }"
            >コラム</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import "@/assets/front_component/js/functions.js";
import $ from "jquery";
import message from "@/apis/Message";
import event from "@/apis/Event";
import user from "@/apis/User";
// import $ from "jquery";
import ScrollHint from "scroll-hint";
import dayjs from "dayjs";

export default {
  name: "event",
  data() {
    return {
      showevent: {},
      get_post_num: 10,
      next_page_id: null,
      status: "all",
      entry: null,
      profileImg: false,
    };
  },
  components: {},
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });

    $(function () {
      new ScrollHint(".js-scrollable", {
        suggestiveShadow: true,
        i18n: {
          scrollable: "スクロールできます",
        },
      });
      setTimeout(ScrollHint, 500);
    });
  },
  created() {
    this.message_id = this.$route.params.id;
    this.showEvent(this.message_id);
    this.getMessageEyecatch(this.message_id);
    this.profileImgApi();
  },
  mounted() {
    document.body.className = "page_control";
    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      new ScrollHint(".js-scrollable", {
        suggestiveShadow: true,
        i18n: {
          scrollable: "スクロールできます",
        },
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
    });
  },
  methods: {
    profileImgApi() {
      Api()
        .get("api/v1/files/users/" + this.$User.id + "?where=key@users")
        .then(
          (response) => {
            if (response != "") {
              let path = response.data.data[0].storage_file_name;
              let fileApiPass = "/api/v1/file/" + path;
              Api()
                .get(fileApiPass, { responseType: "arraybuffer" })
                .then(
                  (response) => {
                    let blob = new Blob([response.data], {
                      type: response.headers["content-type"],
                    });
                    let url = window.URL || window.webkitURL;
                    let src = url.createObjectURL(blob);
                    this.profileImg = src;
                    //console.log(this.profileImg);
                  },
                  (error) => {}
                );
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => {});
    },
    formatDate: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD"),
    formatDate_time: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD"),
    formattime: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD HH:mm:ss"),
    showEvent(id) {
      event
        .show(id)
        .then((response) => {
          if (response != null) {
            this.showevent = response.data.data;
            console.log(this.showevent);
            var start_day = this.formatDate(
              this.showevent.implementation_datetime.start_datetime
            );
            var end_day = this.formatDate(
              this.showevent.implementation_datetime.end_datetime
            );
            var end_time = this.formattime(
              this.showevent.implementation_datetime.end_datetime
            );
            end_time = end_time.substr(end_time.indexOf(" ") + 1);
            console.log(end_time);
            if (start_day == end_day && end_time == "23:59:00") {
              this.showevent.implementation_datetime.end_datetime = null;
            }
            this.getEventAttachedFile(id);
            this.getEventEntry(id);
            if (this.showevent.create_user_id != null) {
              this.getFromUser(this.showevent.create_user_id);
            }
            new ScrollHint(".js-scrollable", {
              suggestiveShadow: true,
              i18n: {
                scrollable: "スクロールできます",
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    getFromUser(user_id) {
      user
        .show(user_id)
        .then((response) => {
          if (response != null) {
            this.showevent.from_user = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },

    getEventAttachedFile(message_id) {
      event
        .showfile(message_id, "key", "event_attached_file")
        .then((response) => {
          if (response.data.data.length != 0) {
            this.showevent.attached_file = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    dlfile(path) {
      this.windowReference = window.open();
      //let fileApiPass = '/api/v1/file/adedd8819fecb6f2abd2756a7771803d?attachment';
      let fileApiPass = "/api/v1/file/" + path;
      console.log(fileApiPass);
      Api()
        .get(fileApiPass, { responseType: "arraybuffer" })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          /**
           * 新規タブ
           */
          this.windowReference.location = window.URL.createObjectURL(blob);
        });
    },
    enterstatuschenge(enter_id, value) {
      var form = {
        status: value,
      };
      event
        .entryput(form, this.message_id, enter_id)
        .then((response) => {
          if (response != null) {
            console.log(response);
            this.getEventEntry(this.message_id);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    getEventEntry(message_id) {
      this.entry = null;
      this.next_page_id = null;
      event
        .entryGetPer(message_id, this.status, this.get_post_num, null)
        .then((response) => {
          if (response.data.data.length != 0) {
            this.total = response.data.total;
            this.entry = response.data.data;
            if (response.data.next_page_url != null) {
              this.next_page_id = response.data.current_page + 1;
              console.log(this.next_page_id);
            }
            this.entry.forEach((user_list) => {
              user
                .show(user_list.from_id)
                .then((response) => {
                  if (response != null) {
                    user_list.from_user = response.data.data;
                  }
                })
                .catch((error) => {
                  console.log(error);
                })
                .finally(() => {});
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    EntryListmore() {
      event
        .entryGetPer(
          this.message_id,
          this.status,
          this.get_post_num,
          this.next_page_id
        )
        .then((response) => {
          if (response.data.data.length != 0) {
            this.total = response.data.total;
            this.entry = this.entry.concat(response.data.data);
            if (response.data.next_page_url != null) {
              this.next_page_id = response.data.current_page + 1;
              console.log(this.next_page_id);
            } else {
              this.next_page_id = null;
            }
            this.entry.forEach((user_list) => {
              user
                .show(user_list.from_id)
                .then((response) => {
                  if (response != null) {
                    user_list.from_user = response.data.data;
                  }
                })
                .catch((error) => {
                  console.log(error);
                })
                .finally(() => {});
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    onStatusChange(event_status) {
      console.log(event_status.target.value);
      this.status = event_status.target.value;
      this.getEventEntry(this.message_id);
    },
    getMessageEyecatch(message_id) {
      event
        .showfile(message_id, "key", "eyecatch")
        .then((response) => {
          if (response != null) {
            this.showevent.eyecatch = response.data.data[0];
            let fileApiPass =
              "/api/v1/file/" + this.showevent.eyecatch.storage_file_name;
            let img = document.getElementById("img_tag_id");
            img.src =
              "data:image/jpeg;base64," + this.showevent.eyecatch.base64;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
  },
  updated() {
    this.$nextTick(function () {
      new ScrollHint(".js-scrollable", {
        suggestiveShadow: true,
        i18n: {
          scrollable: "スクロールできます",
        },
      });
    });
  },
};
</script>

<style scoped>
table {
  width: 100%;
}
/* 外部CSSスタイルシートを追加する */
</style>
